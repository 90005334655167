<template>
  <div class="location-import">
    <div class="d-flex flex-column flex-md-row justify-space-between mb-4">
      <h1 class="mb-4">
        {{ $t('app.page.locationImport.title') }}
      </h1>
      <a
        href="https://docs.google.com/spreadsheets/d/1QtbLz_3UE9MBGiXb0Is-mxolmP7tRP54O0Aqp4PyCbo/edit#gid=0"
        target="_blank"
        class="text-decoration-none">
        <v-btn
          color="secondary"
          depressed>
          {{ $t('app.btn.download') }}
        </v-btn>
      </a>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :fixed-header="!$vuetify.breakpoint.xs"
      :height="!$vuetify.breakpoint.xs ? 285 : 'auto'"
      :loading-text="$t('vuetify.loading')"
      :no-data-text="$t('vuetify.noData')"
      :footer-props="{ itemsPerPageText: $t('vuetify.perPage') }" />
    <div class="title mt-12 mb-6">
      {{ $t('app.page.locationImport.all') }}
      {{ items.length }}
      {{ $t('app.page.locationImport.locations') }}
    </div>
    <v-row>
      <v-col
        cols="12"
        md="4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <div class="d-flex">
            <v-file-input
              v-model="file"
              :rules="fileRules"
              required
              dense
              outlined />
            <v-btn
              color="primary"
              height="40"
              depressed
              @click="csv()">
              {{ $t('app.btn.import') }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        md="8">
        <div class="d-flex justify-end">
          <v-btn
            height="40"
            class="mr-4"
            text
            depressed
            @click="cancel()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <v-btn
            :loading="isSubmitting"
            height="40"
            color="primary"
            depressed
            @click="importCsv()">
            {{ $t('app.btn.confirm') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LocationProvider from '@/resources/axios/providers/location'
import templateLocation from '@/assets/utils/json/template-location.json'

export default {
  name: 'LocationImport',
  data () {
    return {
      file: null,
      valid: true,
      isSubmitting: false,
      headers: [
        { text: this.$t('app.table.header.locationName'), sortable: false, value: 'name' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phone_no' },
        { text: this.$t('app.table.header.type'), sortable: false, value: 'type' },
        { text: this.$t('app.table.header.latitude'), sortable: false, value: 'latitude' },
        { text: this.$t('app.table.header.longitude'), sortable: false, value: 'longitude' },
        { text: this.$t('app.table.header.province'), sortable: false, value: 'province' },
        { text: this.$t('app.table.header.district'), sortable: false, value: 'district' },
        { text: this.$t('app.table.header.subDistrict'), sortable: false, value: 'sub_district' }
      ],
      items: [],
      fileRules: [(v) => !!v || 'File is required']
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    cancel () {
      this.$router.push({ name: 'Location' })
    },
    download () {
      const csv = this.$papa.unparse(templateLocation.data, { header: true })
      this.$papa.download(csv, 'template-location')
    },
    onComplete (results) {
      this.items = results.data
    },
    csv () {
      if (this.$refs.form.validate()) {
        this.$papa.parse(this.file, {
          header: true,
          complete: this.onComplete
        })
      }
    },
    async importCsv () {
      try {
        if (this.isSubmitting) { return }
        if (this.file && this.items.length) {
          this.isSubmitting = true
          const formData = new FormData()
          formData.append('file', this.file)
          const res = await LocationProvider.importLocation(formData)
          if (res) {
            this.showErrorMessage({
              message: this.$t('app.alert.import'),
              type: 'success'
            })
            setTimeout(() => {
              this.closeErrorMessage()
            }, 2000)
            this.isSubmitting = false
            this.cancel()
          }
        } else {
          this.showErrorMessage({
            message: this.$t('app.alert.file'),
            type: 'warning'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .location-import {

  }
</style>
